import layout from "../views/mine/MineList.vue";
export const constantRoute: any = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/AboutProject.vue"),
  },
  {
    path: "/Seller",
    name: "Seller",
    component: () => import("../views/SellerView.vue"),
  },
  {
    path: "/Manager",
    name: "Manager",
    component: () => import("../views/ManagerView.vue"),
  },
  {
    path: "/Incident",
    name: "Incident",
    component: () => import("../views/IncidentView.vue"),
  },
  {
    path: "/Merchantsettlement",
    name: "Merchantsettlement",
    component: () => import("../views/Merchantsettlement/index.vue"),
  },
  {
    path: "/Onlinecourse",
    name: "Onlinecourse",
    component: () => import("../views/Onlinecourse/index.vue"),
  },
  {
    path: "/videodetile",
    name: "videodetile",
    component: () => import("../views/Onlinecourse/videodetile.vue"),
  },
  {
    path: "/SellerViewDetails",
    name: "SellerViewDetails",
    component: () => import("../views/SellerView/index.vue"),
  },
  {
    path: "/Detailedinformation",
    name: "Detailedinformation",
    component: () => import("../views/SellerView/details.vue"),
  },
  {
    path: "/sellerstory",
    name: "sellerstory",
    component: () => import("../views/sellerstory/index.vue"),
  },
  {
    path: "/IncidentDetails",
    name: "IncidentDetails",
    component: () => import("../views/IncidentDetails/index.vue"),
  },
  {
    path: "/Productdetails",
    name: "Productdetails",
    component: () => import("../views/IncidentDetails/Productdetails.vue"),
  },
  {
    path: "/sellerstorydetalis",
    name: "sellerstorydetalis",
    component: () => import("../views/sellerstory/details.vue"),
  },
  {
    path: "/Managerdetail",
    name: "Managerdetail",
    component: () => import("../views/Managerdetail/index.vue"),
  },
  {
    path: "/headTopdetile",
    name: "headTopdetile",
    component: () => import("../views/headTopdetile.vue"),
  },
  {
    path: "/ManagerViewdetali",
    name: "ManagerViewdetali",
    component: () => import("../views/ManagerViewdetali/index.vue"),
  },
  {
    path: "/serchDetail",
    name: "serchDetail",
    component: () => import("../views/serchDetail.vue"),
  },
  {
    path: "/eventdetail",
    name: "eventdetail",
    component: () => import("../views/eventdetail.vue"),
  },
  {
    path: "/Contactus",
    name: "Contactus",
    component: () => import("../views/Contactus.vue"),
  },
  {
    path: "/mine",
    name: "Mine",
    component: layout,
    children: [
      {
        path: "/mine/minevideo",
        name: "MineVido",
        component: () => import("../views/mine/MineVideo.vue"),
      },
    ],
  },
];
