import request from "../utils/request";
// 首页
export const homeList = () => {
  return request({
    method: "get",
    // url:"index/index/old_home_list",
    url: "index/index/home_list",
  })
};
// 卖家
export const sellList = () => {
  return request({
    method: "post",
    url: "web/index/sell_list",
  })
};
// 市场经理
export const marketList = () => {
  return request({
    method: "get",
    url: "web/index/market_list",
  })
};
//   事件
export const eventList = () => {
  return request({
    method: "get",
    url: "web/index/event_list",
  })
};
//  商家支持
export const projectList = () => {
  return request({
    method: "get",
    url: "web/index/project_list",
  })
};
//页脚
export const footerList = () => {
  return request({
    method: "get",
    url: "web/index/footer_list",
  })
};
//公用
export const webConf = () => {
  return request({
    method: "get",
    url: "index/index/web_conf",
  })
};
// 帮助
export const helpList = () => {
  return request({
    method: "get",
    url: "web/index/help_list",
  })
};
// 视频列表
export const videoList = () => {
  return request({
    method: "get",
    url: "web/index/video_list",
  })
}
// 注册
export const verificationCode = (data: any) => {
  return request({
    method: "post",
    url: "system/sms/send_sms",
    data
  })
}

// 登录
export const LogIn = (data: any) => {
  return request({
    method: "post",
    url: "member/login/code_login",
    data
  })
}
// ---------------------新接口
// 首页
export const getnewhome = () => {
  return request({
    method: "post",
    url: "/index/index/home_list",
  })
};
//网站菜单栏
export const getMenubar = () => {
  return request({
    method: "post",
    url: "/index/index/home_menu",
  })
}
//WB商家学院
export const Reqsell_list = () => {
  return request({
    method: "post",
    url: "/web/index/sell_list",
  })
}
//轮播图广告位
export const ReqCarouselmap = () => {
  return request({
    method: "post",
    url: "/index/index/banner_list",
  })
}
//资讯中心
export const Reqmarket_list = () => {
  return request({
    method: "post",
    url: "/web/index/market_list",
  })
}

//通知列表
export const Reqnotice_list = () => {
  return request({
    method: "post",
    url: "/web/member/notice_list",
  })
}
//将通知未读改为已读
export const Reqchange_read_notice = (id: any) => {
  return request({
    method: "post",
    url: "/web/member/change_read_notice",
    data: {
      id
    }
  })
}
//商家入驻
export const Reqindex_child_page = (id: any) => {
  return request({
    method: "post",
    url: "/index/index/index_child_page",
    data: {
      id
    }
  })
}
//跨境服务详情页
export const Reqevent_infoe = (id: any) => {
  return request({
    method: "post",
    url: "/web/index/event_info",
    data: {
      id
    }
  })
}
//WB商家学院-官方合作伙伴的在线课程
export const Reqsell_video_lis = () => {
  return request({
    method: "post",
    url: "/web/index/sell_video_list",
  })
}
//视频详情页
export const Reqvideo_info = (id: any) => {
  return request({
    method: "post",
    url: "/web/index/video_info",
    data: {
      id
    }
  })
}
//认证机构
export const Reqsell_child_page = (id: any) => {
  return request({
    method: "post",
    url: "/web/index/sell_child_page",
    data: {
      id
    }
  })
}
//跨境服务下拉列表页

export const Reqevent_info_list = () => {
  return request({
    method: "post",
    url: "/web/index/event_info_list",
  })
}

//网站菜单栏详情
export const Reqhome_menu_info = (id: any) => {
  return request({
    method: "post",
    url: "/index/index/home_menu_info",
    data: {
      id
    }
  })
}
//记录跳转外部地址
export const Reqjump_log = (data: any) => {
  return request({
    method: "post",
    url: "/web/member/jump_log",
    data
  })
}
//网站首页搜索
export const Reqhome_search = (data: any) => {
  return request({
    method: "post",
    url: "/index/index/home_search",
    data
  })
}
//网站首页搜索
export const Reqhome_search_info = (id: any) => {
  return request({
    method: "post",
    url: "/index/index/search_doings_info",
    data: {
      id
    }
  })
}
//联系我们
export const Reqcontact_us = () => {
  return request({
    method: "post",
    url: "/web/index/contact_us"
  })
}





