import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"
import _imports_0 from '../assets/suos.svg'
import _imports_1 from '../assets/kefu.svg'
import _imports_2 from '../assets/tjkf.svg'
import _imports_3 from '../assets/xx.svg'
import _imports_4 from '../assets/三条杠-copy.png'
import _imports_5 from '../assets/duihao.png'


const _hoisted_1 = ["src"]
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "operations" }
const _hoisted_5 = {
  key: 0,
  class: "t-demo-menu-wem"
}
const _hoisted_6 = { class: "serch" }
const _hoisted_7 = { class: "t-demo-menu-wem" }
const _hoisted_8 = {
  class: "header-ewm",
  style: {"display":"flex","flex-direction":"column","align-items":"center"}
}
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "t-demo-menu--one" }
const _hoisted_11 = { class: "header-msg" }
const _hoisted_12 = {
  style: {"width":"100%"},
  class: "manager_one"
}
const _hoisted_13 = { class: "manager_one-head" }
const _hoisted_14 = { class: "manager_one-head-title" }
const _hoisted_15 = { class: "manager_one-head-title-time" }
const _hoisted_16 = {
  key: 0,
  class: "redrido"
}
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = { class: "t-demo-menu--dark" }
const _hoisted_19 = {
  key: 1,
  class: "popconfir_img"
}
const _hoisted_20 = { class: "popconfirm_div" }
const _hoisted_21 = ["onClick"]
const _hoisted_22 = {
  class: "active_img",
  src: _imports_5,
  alt: ""
}

import { useRouter, useRoute } from "vue-router";
import { ref, onMounted, watch } from "vue";
import { useUserStore } from "../store/index";
import { webConf, getMenubar, Reqnotice_list, Reqchange_read_notice, Reqhome_search } from "../api/api";

export default /*@__PURE__*/_defineComponent({
  __name: 'headTop',
  setup(__props) {

const usestore = useUserStore();
const isMobile = ref(false);
const token = ref<any>("");
const router = useRouter();
const route = useRoute()
const Check = ref(1);
const district = ref(false);
const title = ref("");
const id = ref(-1);
const textconf = ref({});
const Menubarlist = ref<any>()//菜单栏数据
const kfimg = ref()
const optionss = ref([
    { content: "我的", value: 2, path: "" },
    { content: "退出登录", value: 6, path: "" },
]);
const noticelist = ref<any>([])//通知列表
const noticelistlength = ref()
const keywords = ref('')//搜索
onMounted(() => {
    token.value = localStorage.getItem("token");
    if (!token.value) {
        clickHandler(6)
    }
    checkMobile();
    window.addEventListener("resize", checkMobile);
    webConf().then((res) => {
        textconf.value = res.data;
        console.log(res, '2webgsssssssssssss');
        kfimg.value = res.data.web_kf_qrcode_img
    });
    getMenubarlist()
    getnotice_list()
});
function checkMobile() {
    isMobile.value = window.innerWidth <= 1024;
}
//通知消息列表
const getnotice_list = async () => {
    noticelistlength.value = 0
    const res = await Reqnotice_list()
    noticelist.value = res.data.data
    console.log(noticelist.value, 'noticelist.value');
    if (noticelist.value) {
        noticelist.value.data.forEach((item: any, index: any) => {
            if (item.is_read == 0) {
                noticelistlength.value++
            }
        });
    }
}
const handlePanelChange = (item: any) => {
    const { is_read, id } = item
    if (is_read == 0) {
        hange_read(id)
    }
}
//头部跳转对应页面
const gomubiao = (item: any) => {
    if (!item.is_temporary_page) {
        console.log('butiaozhuan');
        router.push({
            path: item.path,
        })
    } else {

        router.push({
            path: '/headTopdetile',
            query: {
                id: item.value
            },
            replace: true
        })
    }
}

//将未读变已读
const hange_read = async (id: any) => {
    const res: any = await Reqchange_read_notice(id)
    getnotice_list()
}
//改变消息
const change = () => {
    // hange_read()
}
const clickHandler = (data: any) => {

    // 导航栏下拉菜单点击事件
    if (data.value === 6) {
        localStorage.removeItem("token");
        token.value = null;
        router.push("/");
        // router.go(0)
        id.value = -1;
    }
    if (data.value === 2) {
        router.push("/mine/minevideo");
        id.value = -1;
    }
    if (data.value === 1) {
        router.push("/Seller");
    }
    if (data.value === 3) {
        router.push("/Manager");
    }
    if (data.value === 4) {
        router.push("/Incident");
    }
    if (data.value === 5) {
        router.push("/about");
    }
    if (data.value === 0) {
        router.push("/");
    }
};
//获取菜单栏
const getMenubarlist = async () => {
    const res = await getMenubar()
    Menubarlist.value = res.data || []
}
const LogIn = () => {
    usestore.visible = true;
};
const SingleChoice = (val: any) => {
    Check.value = val.id;
    if (Check.value == val.id) {
        title.value = val.label;
    }
    district.value = false;
};
const headroute = (value: any, index: any) => {
    localStorage.setItem("ids", index);
    if (localStorage.getItem("token")) {
        router.push(value.path);
        id.value = index;
    } else {
        if (value != "/") {
            usestore.visible = true;
        }
    }
};
const changeserch = () => {
    // const res = await Reqhome_search({ keywords: keywords.value })
    // console.log(res, '1231231');
    // usestore.serchlist = res.data
    router.push({
        path: '/serchDetail',
        query: {
            keywords: keywords.value
        }
    })
}

return (_ctx: any,_cache: any) => {
  const _component_t_dropdown = _resolveComponent("t-dropdown")!
  const _component_t_input = _resolveComponent("t-input")!
  const _component_t_badge = _resolveComponent("t-badge")!
  const _component_t_popup = _resolveComponent("t-popup")!
  const _component_t_collapse_panel = _resolveComponent("t-collapse-panel")!
  const _component_t_collapse = _resolveComponent("t-collapse")!
  const _component_t_list_item = _resolveComponent("t-list-item")!
  const _component_t_list = _resolveComponent("t-list")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_t_head_menu = _resolveComponent("t-head-menu")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ 'top-mobile': isMobile.value, 'top-pc': !isMobile.value })
  }, [
    _createVNode(_component_t_head_menu, {
      theme: "dark",
      height: "120px"
    }, {
      logo: _withCtx(() => [
        _createElementVNode("img", {
          class: "img",
          style: {"cursor":"pointer"},
          height: "150",
          src: textconf.value.web_site_logo,
          alt: "logo"
        }, null, 8, _hoisted_1)
      ]),
      operations: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          (token.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_t_input, {
                    onEnter: changeserch,
                    modelValue: keywords.value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((keywords).value = $event)),
                    borderless: true
                  }, null, 8, ["modelValue"]),
                  _createElementVNode("img", {
                    onClick: changeserch,
                    class: "serch-img",
                    src: _imports_0,
                    alt: ""
                  })
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_7, [
            (token.value)
              ? (_openBlock(), _createBlock(_component_t_popup, {
                  key: 0,
                  onVisibleChange: change,
                  placement: "bottom-right"
                }, {
                  content: _withCtx(() => [
                    _createElementVNode("div", _hoisted_8, [
                      _cache[2] || (_cache[2] = _createElementVNode("img", {
                        src: _imports_2,
                        style: { width: '50px', height: '50px' },
                        alt: ""
                      }, null, -1)),
                      _cache[3] || (_cache[3] = _createElementVNode("p", null, "联系客服", -1)),
                      _createElementVNode("img", {
                        src: kfimg.value,
                        style: { width: '200px', height: '200px' },
                        alt: ""
                      }, null, 8, _hoisted_9)
                    ])
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_t_badge, { count: "0" }, {
                      default: _withCtx(() => _cache[1] || (_cache[1] = [
                        _createElementVNode("img", {
                          src: _imports_1,
                          style: { width: '30px', height: '30px' },
                          alt: ""
                        }, null, -1)
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_10, [
            (token.value)
              ? (_openBlock(), _createBlock(_component_t_popup, {
                  key: 0,
                  onVisibleChange: change,
                  placement: "bottom-right"
                }, {
                  content: _withCtx(() => [
                    _createElementVNode("div", _hoisted_11, [
                      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "header-msg-top" }, [
                        _createElementVNode("p", null, "通知")
                      ], -1)),
                      _createVNode(_component_t_list, {
                        class: "narrow-scrollbar",
                        split: false
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(noticelist.value.data, (item, index) => {
                            return (_openBlock(), _createBlock(_component_t_list_item, {
                              key: item.id
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_12, [
                                  _createVNode(_component_t_collapse, {
                                    borderless: "",
                                    expandIconPlacement: "right",
                                    "default-value": [1],
                                    onChange: ($event: any) => (handlePanelChange(item))
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_t_collapse_panel, { value: index }, {
                                        header: _withCtx(() => [
                                          _createElementVNode("div", _hoisted_13, [
                                            _createElementVNode("div", _hoisted_14, [
                                              _createElementVNode("div", _hoisted_15, _toDisplayString(item.create_time), 1),
                                              (item.is_read != 1)
                                                ? (_openBlock(), _createElementBlock("div", _hoisted_16))
                                                : _createCommentVNode("", true)
                                            ]),
                                            _createElementVNode("div", null, _toDisplayString(item.n_name), 1)
                                          ])
                                        ]),
                                        default: _withCtx(() => [
                                          _createElementVNode("p", {
                                            innerHTML: item.n_detail
                                          }, null, 8, _hoisted_17)
                                        ]),
                                        _: 2
                                      }, 1032, ["value"])
                                    ]),
                                    _: 2
                                  }, 1032, ["onChange"])
                                ])
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_t_badge, { count: noticelistlength.value }, {
                      default: _withCtx(() => _cache[4] || (_cache[4] = [
                        _createElementVNode("img", {
                          src: _imports_3,
                          style: { width: '30px', height: '30px' },
                          alt: ""
                        }, null, -1)
                      ])),
                      _: 1
                    }, 8, ["count"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_18, [
            (!token.value)
              ? (_openBlock(), _createBlock(_component_t_button, {
                  key: 0,
                  onClick: LogIn,
                  class: "login"
                }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode("登录")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (token.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                  (!isMobile.value)
                    ? (_openBlock(), _createBlock(_component_t_dropdown, {
                        key: 0,
                        options: optionss.value,
                        onClick: clickHandler
                      }, {
                        default: _withCtx(() => _cache[7] || (_cache[7] = [
                          _createElementVNode("div", { class: "start-img" }, [
                            _createElementVNode("img", {
                              src: _imports_4,
                              alt: ""
                            })
                          ], -1)
                        ])),
                        _: 1
                      }, 8, ["options"]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Menubarlist.value, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass([index == id.value ? 'active' : '', "title"]),
            key: item.id,
            onClick: ($event: any) => (headroute(item, index))
          }, [
            (item.child.length == 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(item.name), 1))
              : (_openBlock(), _createBlock(_component_t_dropdown, {
                  key: 1,
                  options: item.child,
                  "max-column-width": 200,
                  onClick: gomubiao
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.name), 1)
                  ]),
                  _: 2
                }, 1032, ["options"]))
          ], 10, _hoisted_2))
        }), 128))
      ]),
      _: 1
    }),
    _withDirectives(_createElementVNode("div", _hoisted_20, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "box",
          key: index,
          onClick: ($event: any) => (SingleChoice(item))
        }, [
          _createElementVNode("div", null, _toDisplayString(item.label), 1),
          _withDirectives(_createElementVNode("img", _hoisted_22, null, 512), [
            [_vShow, Check.value == item.id]
          ])
        ], 8, _hoisted_21))
      }), 128))
    ], 512), [
      [_vShow, district.value]
    ])
  ], 2))
}
}

})