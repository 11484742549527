import { defineStore } from 'pinia';
import { useRoute } from 'vue-router';
export const useUserStore = defineStore('user', {
    state: () => ({
        data: {
            access_token: ''
        },
        visible: false,
        menu1Value: '',
        serchlist: '',
        showtoken: true
    }),
    actions: {
    },
    persist: {
        enabled: true
    },
})
