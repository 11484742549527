import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "footer_top" }
const _hoisted_2 = { class: "footer_top_p" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "footer_top_mobile" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "footer_bom" }
const _hoisted_7 = { class: "dialogshow" }
const _hoisted_8 = ["src"]

import { useRouter } from 'vue-router';
import { ref, onMounted } from 'vue';
import { footerList, webConf } from '../api/api';

export default /*@__PURE__*/_defineComponent({
  __name: 'footBot',
  setup(__props) {

const visible = ref(false)//遮盖层
const showimg = ref()
const router = useRouter()
const direction = ref('right');
const onlyIcon = ref(false);
const options = [
  {
    label: '公司',
    name: [
      '关于该项目',
      '测试',
      '对于卖家',
      '市场经理',
      '活动日历',
      '使用门户的规则'
    ],
  },
  {
    label: '我们的计划',
    name: [
      '世界银行合作伙伴',
      'WB流',
      '华宝数码',
      '世界银行大师',
      '世界银行印象',
      '世界银行特许经营权'
    ],
  },
  {
    label: '合作',
    name: [
      '课程认证',
      '与世界银行启动一个项目',
    ],
  },
  {
    label: '我们在社交网络中',
    name: [
      '电报',
      'YouTube',
    ],
  },
  {
    label: '联系我们',
    name: [
      '反馈',
      '支持聊天',
    ],
  },
];
const list = ref([])
const textconf = ref({})
const isMobile = ref(false);

onMounted(() => {
  footerList().then(res => {
    console.log(res);
    list.value = res.data
  })
  webConf().then(res => {
    console.log(res);
    textconf.value = res.data
  })
  checkMobile();
  window.addEventListener('resize', checkMobile);
});

function checkMobile() {
  isMobile.value = window.innerWidth <= 1024;
}

const footck = (val: any) => {
  console.log(val);
  if (val.image) {
    showimg.value = val.image
    visible.value = true
  } else {
    window.open(val.desc, "_blank")
  }
}

return (_ctx: any,_cache: any) => {
  const _component_t_collapse_panel = _resolveComponent("t-collapse-panel")!
  const _component_t_collapse = _resolveComponent("t-collapse")!
  const _component_t_space = _resolveComponent("t-space")!
  const _component_t_dialog = _resolveComponent("t-dialog")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ 'footer-mobile': isMobile.value, 'footer-pc': !isMobile.value })
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list.value, (item, index) => {
        return (_openBlock(), _createElementBlock("div", { key: index }, [
          _createElementVNode("div", null, [
            _createElementVNode("p", _hoisted_2, _toDisplayString(item.cate_name), 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.footer_list, (ele, indexOf) => {
              return (_openBlock(), _createElementBlock("div", { key: indexOf }, [
                _createElementVNode("p", {
                  class: "footer_top_p1",
                  onClick: ($event: any) => (footck(ele))
                }, _toDisplayString(ele.title), 9, _hoisted_3)
              ]))
            }), 128))
          ])
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_t_space, { direction: "vertical" }, {
        default: _withCtx(() => [
          _createVNode(_component_t_collapse, {
            "expand-icon-placement": direction.value,
            "expand-on-row-click": !onlyIcon.value
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list.value, (item, index) => {
                return (_openBlock(), _createBlock(_component_t_collapse_panel, {
                  key: index,
                  value: index,
                  header: item.cate_name
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.footer_list, (ele, indexOf) => {
                      return (_openBlock(), _createElementBlock("p", {
                        key: indexOf,
                        onClick: ($event: any) => (footck(ele))
                      }, _toDisplayString(ele.title), 9, _hoisted_5))
                    }), 128))
                  ]),
                  _: 2
                }, 1032, ["value", "header"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["expand-icon-placement", "expand-on-row-click"])
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("p", null, _toDisplayString(textconf.value.web_site_copyright) + _toDisplayString(textconf.value.web_site_icp), 1)
    ]),
    _createVNode(_component_t_dialog, {
      footer: false,
      header: "",
      preventScrollThrough: "",
      closeOnOverlayClick: "",
      closeOnEscKeydown: "",
      closeBtn: false,
      visible: visible.value,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((visible).value = $event))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("img", {
            src: showimg.value,
            alt: ""
          }, null, 8, _hoisted_8)
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 2))
}
}

})