<template>
    <div :class="{ 'top-mobile': isMobile, 'top-pc': !isMobile }">
        <t-head-menu theme="dark" height="120px">
            <template #logo>
                <img class="img" style="cursor: pointer" height="150" :src="textconf.web_site_logo" alt="logo" />
            </template>
            <!-- 头部 -->
            <div :class="index == id ? 'active' : ''" class="title" v-for="(item, index) in Menubarlist" :key="item.id"
                @click="headroute(item, index)">
                <div v-if="item.child.length == 0">{{ item.name }}</div>
                <t-dropdown v-else :options="item.child" :max-column-width="200" :onClick="gomubiao">
                    {{ item.name }}
                </t-dropdown>
            </div>
            <template #operations>
                <div class="operations">
                    <!-- 搜索 -->
                    <div v-if="token" class="t-demo-menu-wem">
                        <div class="serch">
                            <t-input @enter="changeserch" v-model="keywords" :borderless="true" />
                            <img @click="changeserch" class="serch-img" src="../assets/suos.svg" alt="">
                        </div>
                    </div>
                    <!-- 客服 -->
                    <div class="t-demo-menu-wem">
                        <t-popup v-if="token" :onVisibleChange="change" placement="bottom-right">
                            <t-badge count="0">
                                <img src="../assets/kefu.svg" :style="{ width: '30px', height: '30px' }" alt="">
                            </t-badge>
                            <template #content>
                                <div class="header-ewm"
                                    style="display: flex;flex-direction: column;align-items: center;">
                                    <img src="../assets/tjkf.svg" :style="{ width: '50px', height: '50px' }" alt="">
                                    <p>联系客服</p>
                                    <img :src="kfimg" :style="{ width: '200px', height: '200px' }" alt="">
                                </div>
                            </template>
                        </t-popup>
                    </div>
                    <!-- 消息通知 -->
                    <div class="t-demo-menu--one">
                        <t-popup v-if="token" :onVisibleChange="change" placement="bottom-right">
                            <t-badge :count="noticelistlength">
                                <img src="../assets/xx.svg" :style="{ width: '30px', height: '30px' }" alt="">
                            </t-badge>
                            <template #content>
                                <div class="header-msg">
                                    <div class="header-msg-top">
                                        <p>通知</p>
                                    </div>
                                    <t-list class="narrow-scrollbar" :split="false">
                                        <t-list-item v-for="(item, index) in noticelist.data" :key="item.id">
                                            <div style="width: 100%;" class="manager_one">
                                                <t-collapse borderless expandIconPlacement="right" :default-value="[1]"
                                                    @change="handlePanelChange(item)">
                                                    <t-collapse-panel :value="index">
                                                        <template #header>
                                                            <div class="manager_one-head">
                                                                <div class="manager_one-head-title">
                                                                    <div class="manager_one-head-title-time">{{
                                                                        item.create_time }}</div>
                                                                    <div v-if="item.is_read != 1" class="redrido"></div>
                                                                </div>
                                                                <div>{{ item.n_name }}</div>
                                                            </div>
                                                        </template>
                                                        <template #default>
                                                            <p v-html="item.n_detail"></p>
                                                        </template>
                                                    </t-collapse-panel>
                                                </t-collapse>
                                            </div>
                                        </t-list-item>
                                    </t-list>
                                </div>
                            </template>
                        </t-popup>
                    </div>
                    <div class="t-demo-menu--dark">
                        <t-button v-if="!token" @click="LogIn" class="login">登录</t-button>
                        <div v-if="token" class="popconfir_img">
                            <t-dropdown v-if="!isMobile" :options="optionss" @click="clickHandler">
                                <div class="start-img">
                                    <img src="../assets/三条杠-copy.png" alt="" />
                                </div>
                            </t-dropdown>
                        </div>
                    </div>
                </div>
            </template>
        </t-head-menu>
        <div v-show="district" class="popconfirm_div">
            <div class="box" v-for="(item, index) in options" :key="index" @click="SingleChoice(item)">
                <div>
                    {{ item.label }}
                </div>
                <img v-show="Check == item.id" class="active_img" src="../assets/duihao.png" alt="" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useRouter, useRoute } from "vue-router";
import { ref, onMounted, watch } from "vue";
import { useUserStore } from "../store/index";
import { webConf, getMenubar, Reqnotice_list, Reqchange_read_notice, Reqhome_search } from "../api/api";
const usestore = useUserStore();
const isMobile = ref(false);
const token = ref<any>("");
const router = useRouter();
const route = useRoute()
const Check = ref(1);
const district = ref(false);
const title = ref("");
const id = ref(-1);
const textconf = ref({});
const Menubarlist = ref<any>()//菜单栏数据
const kfimg = ref()
const optionss = ref([
    { content: "我的", value: 2, path: "" },
    { content: "退出登录", value: 6, path: "" },
]);
const noticelist = ref<any>([])//通知列表
const noticelistlength = ref()
const keywords = ref('')//搜索
onMounted(() => {
    token.value = localStorage.getItem("token");
    if (!token.value) {
        clickHandler(6)
    }
    checkMobile();
    window.addEventListener("resize", checkMobile);
    webConf().then((res) => {
        textconf.value = res.data;
        console.log(res, '2webgsssssssssssss');
        kfimg.value = res.data.web_kf_qrcode_img
    });
    getMenubarlist()
    getnotice_list()
});
function checkMobile() {
    isMobile.value = window.innerWidth <= 1024;
}
//通知消息列表
const getnotice_list = async () => {
    noticelistlength.value = 0
    const res = await Reqnotice_list()
    noticelist.value = res.data.data
    console.log(noticelist.value, 'noticelist.value');
    if (noticelist.value) {
        noticelist.value.data.forEach((item: any, index: any) => {
            if (item.is_read == 0) {
                noticelistlength.value++
            }
        });
    }
}
const handlePanelChange = (item: any) => {
    const { is_read, id } = item
    if (is_read == 0) {
        hange_read(id)
    }
}
//头部跳转对应页面
const gomubiao = (item: any) => {
    if (!item.is_temporary_page) {
        console.log('butiaozhuan');
        router.push({
            path: item.path,
        })
    } else {

        router.push({
            path: '/headTopdetile',
            query: {
                id: item.value
            },
            replace: true
        })
    }
}

//将未读变已读
const hange_read = async (id: any) => {
    const res: any = await Reqchange_read_notice(id)
    getnotice_list()
}
//改变消息
const change = () => {
    // hange_read()
}
const clickHandler = (data: any) => {

    // 导航栏下拉菜单点击事件
    if (data.value === 6) {
        localStorage.removeItem("token");
        token.value = null;
        router.push("/");
        // router.go(0)
        id.value = -1;
    }
    if (data.value === 2) {
        router.push("/mine/minevideo");
        id.value = -1;
    }
    if (data.value === 1) {
        router.push("/Seller");
    }
    if (data.value === 3) {
        router.push("/Manager");
    }
    if (data.value === 4) {
        router.push("/Incident");
    }
    if (data.value === 5) {
        router.push("/about");
    }
    if (data.value === 0) {
        router.push("/");
    }
};
//获取菜单栏
const getMenubarlist = async () => {
    const res = await getMenubar()
    Menubarlist.value = res.data || []
}
const LogIn = () => {
    usestore.visible = true;
};
const SingleChoice = (val: any) => {
    Check.value = val.id;
    if (Check.value == val.id) {
        title.value = val.label;
    }
    district.value = false;
};
const headroute = (value: any, index: any) => {
    localStorage.setItem("ids", index);
    if (localStorage.getItem("token")) {
        router.push(value.path);
        id.value = index;
    } else {
        if (value != "/") {
            usestore.visible = true;
        }
    }
};
const changeserch = () => {
    // const res = await Reqhome_search({ keywords: keywords.value })
    // console.log(res, '1231231');
    // usestore.serchlist = res.data
    router.push({
        path: '/serchDetail',
        query: {
            keywords: keywords.value
        }
    })
}
</script>

<style lang="scss">
.serter {
    // position: absolute;
}

.t-dropdown__item--theme-default:hover {
    background-color: rgba(0, 0, 0, 0.3) !important;
    color: #8efd07 !important;
}

.t-dropdown {
    background-color: rgba(27, 27, 27, 0.4) !important;
    border: 0 !important;
}

.t-dropdown__item--theme-default {
    color: #fff !important;
    font-size: 18px !important;
}

.top-pc {
    position: relative;
    max-width: 1392px;
    min-width: 1024px;
    margin: 0 auto;
    font-size: 20px;
    display: flex;
    align-items: center;

    .popconfir_img {
        width: 30px;
        height: 50px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .t-menu--dark.t-head-menu {
        background-color: rgba(255, 255, 255, 0);

        .t-head-menu__inner {
            height: 90px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .operations {
                display: flex;
                gap: 20px;
                align-items: center;

                .t-demo-menu-wem {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .serch {
                        display: flex;
                        align-items: center;
                        background-color: #fff;
                        border-radius: 16px;
                        padding: 5px;

                        .serch-inp {
                            border: 0;
                        }

                        .serch-img {
                            width: 20px;
                            height: 20px;
                            margin-left: 20px;
                        }
                    }
                }

                .t-demo-menu--one {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                }

                .t-demo-menu--dark {
                    display: flex;
                    align-items: center;

                    .login {
                        background: #7001fd;
                        border: 0;
                        font-size: 20px;
                        height: 45px;
                        line-height: 45px;
                        border-radius: 15px;
                        padding: 0 23px;
                        box-sizing: border-box;
                    }
                }
            }


            .title {
                margin-right: 40px;
                font-size: 20px;
                color: #ffffff;
                cursor: pointer;
            }


            .active {
                margin-right: 40px;
                font-size: 20px;
                color: #8efd07;
            }

            .popconfirm {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 190px;

                .titles {
                    margin-right: 40px;
                    font-size: 20px;
                    color: white;
                    cursor: pointer;

                    &:hover {
                        color: #d0cdd4;
                    }
                }
            }
        }
    }

    .popconfirm_div {
        position: absolute;
        background: white;
        top: 90%;
        right: 9%;
        color: #000;
        z-index: 999;
        border-radius: 8px;

        .box {
            width: 184px;
            padding: 10px 20px;
            box-sizing: border-box;
            margin: 3px 5px;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;

            &:hover {
                background: #dcdcdc;
                border-radius: 8px;
            }
        }
    }

    .active_img {
        width: 25px;
    }

    .t-popconfirm__buttons {
        display: none;
    }

    .t-popup__content--arrow {
        width: 184px;
        margin: 0;
    }

    .t-popconfirm__content {
        padding: 5px !important;

        .t-popconfirm__body>.t-icon {
            display: none;
        }

        .t-popconfirm__inner {
            width: 100%;

            .confirm {
                width: 100%;
                border-radius: 11px;
                padding: 10px;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;

                &:hover {
                    background: #eeeeee;
                }
            }

            .confirm_p {
                padding: 0;
                margin: 0;
                font-size: 18px;
            }
        }

        .confirm_img {
            width: 25px;
            height: 25px;
        }
    }

    .spinner-logo {
        transform: perspective(140px) rotateX(40deg) rotateY(20deg) rotate(0deg);
        animation: zRotation 18s linear infinite;
    }

    .in-logo {
        position: absolute;
        top: 13px;
        left: 0px;
        width: 52px;
        height: 52px;
    }

    .fill-white {
        fill: #fff;
    }

    .w-\[180px\] {
        width: 180px;
    }

    .h-\[42px\] {
        height: 56px;
    }

    .flex {
        display: flex;
    }

    @keyframes zRotation {
        0% {
            transform: perspective(140px) rotateX(40deg) rotateY(20deg) rotate(0deg);
        }

        100% {
            transform: perspective(140px) rotateX(40deg) rotateY(20deg) rotate(1turn);
        }
    }
}

.top-mobile {
    color: white;

    .img {
        width: 100%;
    }

    .title,
    .popconfirm,
    .t-button {
        display: none;
    }

    .popconfir_img {
        margin-top: 50%;

        img {
            width: 6.25vw;
        }
    }

    .t-menu--dark.t-head-menu {
        background-color: rgba(255, 255, 255, 0);
    }

    .spinner-logo {
        transform: perspective(140px) rotateX(40deg) rotateY(20deg) rotate(0deg);
        animation: zRotation 18s linear infinite;
    }

    .in-logo {
        position: absolute;
        top: 13px;
        left: 0px;
        width: 52px;
        height: 52px;
    }

    .fill-white {
        fill: #fff;
    }

    .w-\[180px\] {
        width: 103px;
    }

    .h-\[42px\] {
        height: 30px;
    }

    .flex {
        display: flex;
    }

    @keyframes zRotation {
        0% {
            transform: perspective(140px) rotateX(40deg) rotateY(20deg) rotate(0deg);
        }

        100% {
            transform: perspective(140px) rotateX(40deg) rotateY(20deg) rotate(1turn);
        }
    }
}

.header-msg {
    width: 400px;
    height: 500px;
    // height: 440px;
    margin: calc(0px - var(--td-comp-paddingTB-xs)) calc(0px - var(--td-comp-paddingLR-s));

    &-top {
        position: relative;
        font: var(--td-font-title-medium);
        color: var(--td-text-color-primary);
        text-align: left;
        padding: var(--td-comp-paddingTB-l) var(--td-comp-paddingLR-xl) 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &-bottom {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: var(--td-comp-paddingTB-s) var(--td-comp-paddingLR-s);
        border-top: 1px solid var(--td-component-stroke);

        &-link {
            text-decoration: none;
            cursor: pointer;
            color: var(--td-text-color-placeholder);
        }
    }

    .manager_one-head {
        display: flex;
        flex-direction: column;

        .manager_one-head-title {
            display: flex;
            justify-content: space-between;

            .manager_one-head-title-time {
                color: #ccc;
            }

            .redrido {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: red;
            }
        }

    }

    .t-list {
        height: calc(100% - 104px);
        padding: var(--td-comp-margin-s) var(--td-comp-margin-s);
    }

    .empty-list {
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
    }
}
</style>